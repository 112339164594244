import { readState } from "@/__main__/app-state.mjs";
import getData from "@/__main__/get-data.mjs";
import fetchSeasons from "@/game-destiny2/fetches/fetch-seasons.mjs";
import fetchGuardians from "@/game-destiny2/fetches/guardians-fetch-data.mjs";
import { BungieProfileValidator } from "@/game-destiny2/models/destiny2-bungie-profile.mjs";
import { MatchValidator } from "@/game-destiny2/models/destiny2-match.mjs";
import { MatchListValidator } from "@/game-destiny2/models/destiny2-match-list.mjs";
import * as API from "@/game-destiny2/utils/api.mjs";
import { devError } from "@/util/dev.mjs";

export default async function fetchData([bungieIdParam, gameId]: [
  string,
  string,
]) {
  try {
    const {
      destiny2: { oauth },
    } = readState;
    const bungieId = Number.isInteger(Number(bungieIdParam))
      ? bungieIdParam
      : oauth[Object.keys(oauth)[0]]?.bungie_uid;
    if (!bungieId || !gameId) throw new Error("No bungie id and/or game id");

    await Promise.all([
      getData(API.getProfile({ bungieId }), BungieProfileValidator, [
        "destiny2",
        "profiles",
        bungieId,
      ]),
      fetchGuardians([bungieId]),
      fetchSeasons(),
      getData(API.getMatch({ activityId: gameId }), MatchValidator, [
        "destiny2",
        "match",
        gameId,
      ]),
      (async () => {
        const activityIds = await getData(
          API.getMatchList({ bungieId }),
          MatchListValidator,
          ["destiny2", "matchList", bungieId],
        );
        for (const activityId of activityIds) {
          await getData(API.getMatch({ activityId }), MatchValidator, [
            "destiny2",
            "match",
            activityId,
          ]);
        }
      })(),
    ]);
  } catch (e) {
    devError("Failed to fetch match page data", e);
  }
}
